import React from 'react'
import styled from '@emotion/styled'

const Text = styled.div`
  text-align: left;
  width: 100%;
  line-height: 1.6;
  padding: 30px 15px;
  font-size: ${props => props.theme.fontSizes[2]}px;
  a {
    color: ${props => props.theme.colors.red};
  }
  @media (min-width: ${props => props.theme.responsive.medium}) {
    padding: 30px;
    font-size: ${props => props.theme.fontSizes[4]}px;
  }
`

const Subheadline = props => <Text>{props.children}</Text>

export default Subheadline
