import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import Subheadline from './Subheadline'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  h1 {
    padding: 30px 15px;
    border-bottom: 2px solid #000;
    @media (min-width: ${props => props.theme.responsive.medium}) {
      padding: 30px;
    }
  }
`

const TermineContainer = styled.div`
  width: 100%;

  > div {
    padding: 0 30px 30px;
  }
`

const ContentFernwartung = props => {
  useEffect(() => {
    const script = document.createElement('script')
    script.async = true
    script.defer = true
    script.src = 'https://cdn.oncehub.com/mergedjs/so.js'
    document.getElementById('SOIDIV_extendIT').appendChild(script)
  }, [])
  return (
    <Container>
      {props.children}
      <Subheadline>
        Sie wünschen ein unverbindliches Erstgespräch mit uns? Hier können Sie
        gleich Ihren Wunschtermin eintragen – online oder vor Ort.
      </Subheadline>
      <TermineContainer>
        <div
          key="sc-once-1"
          id="SOIDIV_extendIT"
          data-so-page="extendIT"
          data-height="550"
          data-style="border: 1px solid #d8d8d8; min-width: 290px; max-width: 1118px;"
          data-psz="00"
        ></div>
      </TermineContainer>
    </Container>
  )
}

export default ContentFernwartung
