import React from 'react'
import styled from '@emotion/styled'

const Title = styled.h1`
  font-size: ${props => props.theme.fontSizes[7]}px;
  font-weight: 400;
  text-transform: uppercase;
  text-align: left;
  margin: 0;
  line-height: 1;

  @media (max-width: ${props => props.theme.responsive.largeMax}) {
    font-size: ${props => props.theme.fontSizes[6]}px;
  }

  @media (max-width: ${props => props.theme.responsive.mediumMax}) {
    font-size: ${props => props.theme.fontSizes[5]}px;
  }

  @media (max-width: ${props => props.theme.responsive.smallMax}) {
    font-size: ${props => props.theme.fontSizes[4]}px;
  }

  span {
    margin: 0 0 0 0.25em;
  }
  a {
    transition: all 0.2s;
    color: ${props => props.theme.colors.text};
    &:hover {
      color: ${props => props.theme.colors.grey};
    }
  }
`

const PageTitle = props => {
  return <Title small={props.small}>{props.children}</Title>
}

export default PageTitle
