import React from 'react'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageTitle from '../components/PageTitle'
import ContainerHero from '../components/ContainerHero'
import SEO from '../components/SEO'
import ContentTermine from '../components/ContentTermine'

const Termine = () => {
  return (
    <Layout>
      <SEO
        title="Termine"
        description="Vereinbaren Sie sich direkt einen unverbindlichen Beratungstermin mit uns."
      />
      <Container>
        <ContainerHero newsletter>
          <ContentTermine>
            <PageTitle>Terminvereinbarung</PageTitle>
          </ContentTermine>
        </ContainerHero>
      </Container>
    </Layout>
  )
}

export default Termine
